import * as React from "react";
import { Widget } from "@typeform/embed-react";

const ReserveMySpotPage = () => {
  return (
    <Widget
      id="pQNtkwfc"
      style={{ height: "100vh" }}
      className="my-form"
      inlineOnMobile
    />
  );
};

export default ReserveMySpotPage;
